import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { changeEmployeeStatus, fileUploadCard, getEmployeesDetails, spouceChildDeleteDetails } from "../axios/apicall";
import Loader from "../components/Loader";
import NoData from "../components/NoData";
import "../assets/SCSS/pages/employees-sammarry.scss";
import "../assets/SCSS/pages/file-upload-popup.scss";
import { useNavigate, useParams } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";

const EmployeesSummarry = () => {
  const Navigate = useNavigate();
  const { id } = useParams();

  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [status, setStatus] = useState(null);
  const [relativesList, setRelativeList] = useState({
    child: [],
    spouse: [],
  });
  const [refresh, setRefresh]=useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([
		{ value: "medical", label: "Medical" },
		{ value: "dental", label: "Dental" },
		{ value: "vision", label: "Vision" },
	]);
  const [selectedDocumentType, setSelectedDocumentType] = useState({value: "medical", label: "Medical"});

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    setShowLoader(true);
    getEmployeesDetails(id)
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.data) {
            setShowLoader(false);
            setEmployeeDetail(res.data.data);
            setStatus(res.data.data.userStatus);

            const child = [],
              spouse = [];
            res.data.data.relatives.forEach((i) => {
              if (i.userRealation === "Child") {
                child.push(i);
              }
              if (i.userRealation === "Spouse") {
                spouse.push(i);
              }
            });
            setRelativeList({
              spouse,
              child,
            });
          }
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }, [status, refresh]);

  document.title = "Employee Summarry";
  const onStatusChange = (status) => {
    MySwal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00B050",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      customClass: "confirm-box",
      backdrop: false,
    }).then((result) => {
      if (result.value) {
        changeEmployeeStatus(id, status)
          .then((res) => {
            if (res.data.status === "success") {
              toast.success(res.data.message);
              setStatus(+status);
            }
            if (res.data.status === "failure") {
              toast.error(res.data.message);
            }
          })
          .catch(() => {
            toast.error("Failed to update the status");
          });
      }
    });
  };

  const DeleteClickHandler = (userId, parentUserId) => {
    MySwal.fire({
      title: "You are about to delete the user. ",
      text: "This will delete user from the system. Are you sure?",
      type: "warning",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#00B050",
      cancelButtonColor: "#d33",
      confirmButtonText: "Remove",
      customClass: {
        title: 'custom-title-class',
      },
      backdrop: false,
    }).then((result) => {
      if (result.value) {
        spouceChildDeleteDetails(userId, parentUserId)
          .then((res) => {
            if (res.data.status === "success") {
              toast.success(res.data.message);
              setRefresh(Math.floor(Math.random() * 100));
            }
            if (res.data.status === "failure") {
              toast.error(res?.data?.message);
            }
          })
          .catch(() => {
            toast.error("Failed to update the status");
          });
      }
    });
  };

  const ShowPopupHandler = (id, has_medical_plan, has_dental_plan, has_vision_plan) => {
    setSelectedId(id);
    setShowPopup(true);
    const documentTypes = [
      ...(has_medical_plan ? [{ value: "medical", label: "Medical" }] : []),
      ...(has_dental_plan ? [{ value: "dental", label: "Dental" }] : []),
      ...(has_vision_plan ? [{ value: "vision", label: "Vision" }] : []),
    ];
    setDocumentTypeOptions(documentTypes);
  }
  const closePopUpHandler = () => {
    setShowPopup(false);
    setShowLoader(false);
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
 
  const handleFileUpload = () => {
    if (selectedFile && selectedId) {
      const fileExtension = selectedFile.name.split('.').pop();

      if (fileExtension !== 'pdf') {
        toast.error("Unsupported file format.");
        return false;
      }
      if (selectedFile.size > 100 * 1024 * 1024) { // 100 MB in bytes
        toast.error("File size exceeds the maximum allowed limit (100 MB).");
        return false;
      }
      setShowLoader(true);
      const fileUrl = URL.createObjectURL(selectedFile);
      setSelectedFileUrl(fileUrl);

      const form_data = new FormData();
      form_data.append('file', selectedFile);

      fileUploadCard(selectedId,form_data, selectedDocumentType.value)
        .then((res) => {
          setSelectedFile(null)
          
          if (res.data.status_code === 200) {
            toast.success(res.data.message);
            setShowLoader(false);
            setShowPopup(false);
            setSelectedFile(null);
            setRefresh((prevRefresh) => !prevRefresh);
          }else{
            toast.error(res.data.message);
            setSelectedFile(null)
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setShowLoader(false);
        });
    }
  };

  const handleDocumentSelectChange = (selectedOption) => {
    setSelectedDocumentType(selectedOption);

	};

  return (<>
    <main className="page">
      <div className="page-header">
        <h3 className="mb-0 d-flex align-center text-primary employee-summary-heading">
          <i
            className="cursor-pointer text-body mr-1 icon-back"
            onClick={() => Navigate(-1)}
          ></i>
          Employee Summary
        </h3>
        {!employeeDetail?.isAdmin && (
          <div className="change-status">
            <span>Status:</span>
            <div>
              <button
                className={`${
                  employeeDetail?.userStatus === 1 ? "active" : "in-active"
                } left`}
                onClick={() => onStatusChange(1)}
              >
                Active
              </button>
              <button
                className={`${
                  employeeDetail?.userStatus === 0 ? "active" : "in-active"
                } right`}
                onClick={() => onStatusChange(0)}
              >
                In Active
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="row align-start">
        <div className="content full">
          <div className="content-box">
            <Loader show={showLoader} />
            {employeeDetail && (
              <>
                {/* ---------------main full width section  starts-------------------- */}  
                <div className="left main-parent-section">
                    <div className="member-card">
                      <div>
                        <span className="member-sn member-icon-size">
                          {`${employeeDetail?.userFirstName.charAt(
                            0
                          )}${employeeDetail?.userLastName.charAt(0)}`} 
                         
                        </span>
                      </div>

                      <div className="member-card-detail">
                        <div className="member-name">
                          <strong>
                            {employeeDetail.userFullName} 
                          </strong>
                        </div>
                        <div className="child-main-detail">
                          <ul className="member-card-list parent-card-detail">
                            <li className="member-card-items">
                              <span className="bold">SSN: </span>
                              <p>{employeeDetail.userSSN}</p>
                            </li>
                            <li className="member-card-items member-planname-relation">
                              <span className="bold">Enrolled for : </span>
                            <p className="inline-text">
                            {(() => {
                              const plans = [
                                employeeDetail.has_dental_plan &&
                                  (employeeDetail?.dental_plan_document ? (
                                    <a key="dental" href={employeeDetail.dental_plan_document} target="_blank" rel="noopener noreferrer">
                                      MetLife Dental Plan
                                    </a>
                                  ) : (
                                    'MetLife Dental Plan'
                                  )),

                                employeeDetail.has_vision_plan &&
                                  (employeeDetail?.vision_plan_document ? (
                                    <a key="vision" href={employeeDetail.vision_plan_document} target="_blank" rel="noopener noreferrer">
                                      MetLife Vision Plan
                                    </a>
                                  ) : (
                                    'MetLife Vision Plan'
                                  )),

                                employeeDetail.has_medical_plan &&
                                  (employeeDetail.userTiear === 1
                                    ? employeeDetail?.medical_plan_document ? (
                                        <a key="umr" href={employeeDetail.medical_plan_document} target="_blank" rel="noopener noreferrer">
                                          UMR Plan A
                                        </a>
                                      ) : (
                                        'UMR Plan A'
                                      )
                                    : employeeDetail.userTiear === 2
                                    ? employeeDetail?.medical_plan_document ? (
                                        <a key="uhc" href={employeeDetail.medical_plan_document} target="_blank" rel="noopener noreferrer">
                                          UHC Plan B (PPO)
                                        </a>
                                      ) : (
                                        'UHC Plan B (PPO)'
                                      )
                                    : null),
                              ].filter(Boolean); // Remove falsy values

                              return plans.length > 0 ? (
                                plans.map((element, index) => (
                                  <React.Fragment key={index}>
                                    {element}
                                    {index !== plans.length - 1 && ', '}
                                  </React.Fragment>
                                ))
                              ) : (
                                '-'
                              );
                            })()}
                            </p>
                            </li>
                            <li className="member-card-items">
                              <span className="bold">Email ID: </span>
                              <p className="member-email-id">{employeeDetail.userEmail ? employeeDetail.userEmail : " - "}</p>
                            </li>
                            <li className="member-card-items member-planname-relation">
                              <span className="bold">Relation: </span>
                              <p>
                                <strong className="text-success text-capitalize">
                                  {employeeDetail.userRealation ? employeeDetail.userRealation : " - "}
                                </strong>
                              </p>
                            </li>
                            <li className="member-card-items">
                              <span className="bold">Contact: </span>
                              <p>{employeeDetail.userContact ? employeeDetail.userContact : " - "}</p>
                            </li>
                          </ul>
                          <section className="member-section-btn">
                            {
                              (employeeDetail?.has_medical_plan || employeeDetail?.has_dental_plan || employeeDetail?.has_vision_plan) && (
                                <button className="btn-common upload-card-btn" onClick={() => ShowPopupHandler(employeeDetail?.id, employeeDetail.has_medical_plan, employeeDetail.has_dental_plan, employeeDetail.has_vision_plan)}><i className="icon-upload-card"></i>Upload Card</button>
                              )
                            }
                          </section>
                        </div>
                      </div>
                    </div>
                  {/* )
                  )
                  } */}
                </div>

                {/* ---------------main full width section  ends-------------------- */}

                {/* ---------------------------new section starts------------------- */}

                <div className="section-row">
                  <div className="left">
                    {relativesList.spouse.map((i, key) => (<>
                      {/* <button className="delete-spouce" onClick={() => DeleteClickHandler(i.id, employeeDetail.id)}> Delete</button> */}
                      <div className="member-card" key={key}>
                        <div>
                          <span className="member-sn member-icon-size">
                            {`${i?.userFirstName.charAt(
                              0
                            )}${i?.userLastName.charAt(0)}`}{" "}
                          </span>
                        </div>

                        <div className="member-card-detail">
                          <div className="member-name">
                            <strong>
                              {i.userFullName}  
                            </strong>
                          </div>
                          <div className="child-main-detail">
                            <ul className="member-card-list">
                              <li className="member-card-items">
                                <span className="bold">SSN: </span>
                                <p>{i.userSSN}</p>
                              </li>
                              <li className="member-card-items member-planname-relation">
                                <span className="bold">Enrolled for : </span>

                                <p className="inline-text">
                                {(() => {
                                  const plans = [
                                    i.has_dental_plan &&
                                      (i?.dental_plan_document ? (
                                        <a key="dental" href={i.dental_plan_document} target="_blank" rel="noopener noreferrer">
                                          MetLife Dental Plan
                                        </a>
                                      ) : (
                                        'MetLife Dental Plan'
                                      )),

                                    i.has_vision_plan &&
                                      (i?.vision_plan_document ? (
                                        <a key="vision" href={i.vision_plan_document} target="_blank" rel="noopener noreferrer">
                                          MetLife Vision Plan
                                        </a>
                                      ) : (
                                        'MetLife Vision Plan'
                                      )),

                                    i.userTiear === 1
                                      ? i?.medical_plan_document ? (
                                          <a key="umr" href={i.medical_plan_document} target="_blank" rel="noopener noreferrer">
                                            UMR Plan A
                                          </a>
                                        ) : (
                                          'UMR Plan A'
                                        )
                                      : i.userTiear === 2
                                      ? i?.medical_plan_document ? (
                                          <a key="uhc" href={i.medical_plan_document} target="_blank" rel="noopener noreferrer">
                                            UHC Plan B (PPO)
                                          </a>
                                        ) : (
                                          'UHC Plan B (PPO)'
                                        )
                                      : null,
                                  ].filter(Boolean); // Remove empty values

                                  return plans.length > 0 ? (
                                    plans.map((element, index) => (
                                      <React.Fragment key={index}>
                                        {element}
                                        {index !== plans.length - 1 && ', '}
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    '-'
                                  );
                                })()}
                              </p>
                              </li>
                              <li className="member-card-items">
                                <span className="bold">Email ID: </span>
                                <p className="member-email-id">{i.userEmail ? i.userEmail : " - "}</p>
                              </li>
                              <li className="member-card-items member-planname-relation">
                                <span className="bold">Relation: </span>
                                <p>
                                  <strong className="text-success text-capitalize">
                                    {i.userRealation ? i.userRealation : " - "}
                                  </strong>
                                </p>
                              </li>
                            </ul>
                            <section className="member-section-btn">
                              {
                                (employeeDetail?.has_medical_plan || employeeDetail?.has_vision_plan || employeeDetail?.has_dental_plan) && (
                              <button className="btn-common upload-card-btn" onClick={() => ShowPopupHandler(i?.id, i.has_medical_plan, i.has_dental_plan, i.has_vision_plan)}><i className="icon-upload-card"></i>Upload Card</button>
                                )
                              }

                              <button className="btn-common delete-btn" onClick={() => DeleteClickHandler(i.id, employeeDetail.id)}><i className="icon-delete"></i>Delete Account</button>
                            </section>
                          </div>
                        </div>
                      </div>
                    </> )
                    )}
                  </div>
                  <div className="right">
                    {relativesList.child.map((i, key) => (
                      <div className="member-card member-card-icon" key={key}>
                        {/* <button className="delete-spouce" onClick={() => DeleteClickHandler(i.id, employeeDetail.id)}> Delete</button> */}
                        <div>
                          <span className="member-sn member-icon-size">
                            {`${i?.userFirstName.charAt(
                              0
                            )}${i?.userLastName.charAt(0)}`}
                          </span>
                        </div>

                        <div className="member-card-detail">
                          <div className="member-name">
                            <strong>
                              {i.userFullName}
                            </strong>
                          </div>
                          <div className="child-main-detail">
                            <ul className="member-card-list">
                              <li className="member-card-items">
                                <span className="bold">SSN: </span>
                                <p>{i.userSSN}</p>
                              </li>

                              <li className="member-card-items member-planname-relation">
                                <span className="bold">Enrolled for  : </span>
                                <p className="inline-text">
                                {(() => {
                                  const plans = [
                                    i?.dental_plan_document ? (
                                      <a key="dental" href={i.dental_plan_document} target="_blank" rel="noopener noreferrer">
                                        MetLife Dental Plan
                                      </a>
                                    ) : (
                                      'MetLife Dental Plan'
                                    ),

                                    i?.vision_plan_document ? (
                                      <a key="vision" href={i.vision_plan_document} target="_blank" rel="noopener noreferrer">
                                        MetLife Vision Plan
                                      </a>
                                    ) : (
                                      'MetLife Vision Plan'
                                    ),

                                    i?.has_medical_plan &&
                                      (i?.userTiear === 1
                                        ? i?.medical_plan_document ? (
                                            <a key="umr" href={i.medical_plan_document} target="_blank" rel="noopener noreferrer">
                                              UMR Plan A
                                            </a>
                                          ) : (
                                            'UMR Plan A'
                                          )
                                        : i?.userTiear === 2
                                        ? i?.medical_plan_document ? (
                                            <a key="uhc" href={i.medical_plan_document} target="_blank" rel="noopener noreferrer">
                                              UHC Plan B (PPO)
                                            </a>
                                          ) : (
                                            'UHC Plan B (PPO)'
                                          )
                                        : null),
                                  ].filter(Boolean); // Remove falsy values (false, undefined, null, etc.)

                                  return plans.length > 0 ? (
                                    plans.map((element, index) => (
                                      <React.Fragment key={index}>
                                        {element}
                                        {index !== plans.length - 1 && ', '}
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    '-'
                                  );
                                })()}
                              </p>
                              </li>

                              <li className="member-card-items">
                                <span className="bold">Email ID: </span>
                                <p className="member-email-id">{i.userEmail ? i.userEmail : " - "}</p>
                              </li>

                              <li className="member-card-items member-planname-relation">
                                <span className="bold">Relation </span>
                                <p>
                                  <strong className="text-success text-capitalize">
                                    {i.userRealation ? i.userRealation : " - "}
                                  </strong>
                                </p>
                              </li>
                            </ul>

                            <section className="member-section-btn">
                              {
                                (employeeDetail?.has_medical_plan || employeeDetail?.has_dental_plan || employeeDetail?.has_vision_plan) && (
                                  <button className="btn-common upload-card-btn" onClick={() => { ShowPopupHandler(i.id, i.has_medical_plan, i.has_dental_plan, i.has_vision_plan) }}><i className="icon-upload-card"></i>Upload Card</button>
                                )
                              }

                              <button className="btn-common delete-btn" onClick={() => DeleteClickHandler(i.id, employeeDetail.id)}><i className="icon-delete"></i>Delete Account</button>
                            </section>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>

    {
    showPopup && (
      <div className="popup" id="popup-1">
        <div className="overlay"></div>
        <div className="content">
          <button onClick={closePopUpHandler}  className="close-btn">
            &times;
          </button>
          <h3 className="heading">Upload Card</h3>
          <div className="main-content">
            <Loader show={showLoader} />
            <div className="main-content-top">
              <div>
                <i className="icon-cloud-upload"></i>
              </div>
              <h6 className="file-heading">Upload File</h6>
              <p className="file-text">
               Only PDF file is allowed.
              </p>
              <div className="browsefile-div">
                <div className="input-browse-file">
                  <button
                    className="browse-file-btn fw-300"
                    onClick={() => setShowFileInput(true)}
                  >
                    Browse File
                  </button>

                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                  />
                </div>
                  {selectedFile && (
                    <p className="selected-file-name">
                      {selectedFile.name}
                    </p>
                  )}
             
              </div>
            </div>
            <div className="d-flex">
                <Select
                  className="dropdown"
                  options={documentTypeOptions}
                  value={documentTypeOptions.find((option) => option.value === selectedDocumentType.value)}
                  onChange={handleDocumentSelectChange}
                  placeholder="Select Document Type"
                />
              </div>
           
          </div>
          <div className="footer-content">
            <button className="btn btn-primary" onClick={handleFileUpload}>Upload</button>
          </div>
        </div>
      </div>
     )
  }
  </>
  );
};

export default EmployeesSummarry;
